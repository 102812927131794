import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThresholdType } from '@weave/schema-gen-ts/dist/schemas/quality-metrics/quality_metrics.pb';

const THRESHOLD_TYPES_DESCRIPTION: Record<ThresholdType, string> = {
  SCALE_LTE:
    'SCALE_LTE is applied when higher risk points are assigned to lower values of the target metric. For example, in the case of "Test Coverage Percentage", a lower test coverage percentage (target metric) results in higher risk points',
  SCALE_GTE:
    'SCALE_GTE is applied when higher risk points are assigned to higher values of the target metric. For example, in the case of "Cyclomatic Complexity", a higher Cyclomatic Complexity (target metric) results in higher risk points',
  BINARY_INVERSE:
    'BINARY_INVERSE is applied when higher risk points are assigned to the absence of the target metric. For example, in the case of "Repo Owner Not Blank", the absence of the repo owner results in higher risk points',
  BINARY: 
    'BINARY is applied when higher risk points are assigned to the presence of the target metric. For example, "Critical Vulnerability Present" would be a metric where the presence of a critical vulnerability results in higher risk points',
};

interface Props {
  thresholdType?: string;
  threshold?: Record<string, string | number>;
}

export default function ThresholdInfoButton({
  threshold,
  thresholdType,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const description =
    THRESHOLD_TYPES_DESCRIPTION[thresholdType as ThresholdType] ?? '';

  return (
    <React.Fragment>
      <IconButton aria-label="delete" size="small" onClick={handleClickOpen}>
        <InfoIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" variant="subtitle1">
          Threshold Type : <strong>{thresholdType ?? 'Unknown'}</strong>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ display: 'flex', flexDirection: 'column', minWidth: 400 }}
        >
          {description && (
            <DialogContentText
              id="alert-dialog-description"
              variant="caption"
              gutterBottom
            >
              {description}
            </DialogContentText>
          )}

          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 400,
              maxWidth: 400,
              boxShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
              borderRadius: 2,
              overflow: 'auto',
              alignSelf: 'center',
            }}
          >
            <Table stickyHeader size="small" aria-label="threshold info table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="right"
                    width="50%"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Target Metric Value
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    Risk Points
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(threshold ?? {}).map(([key, value]) => (
                  <TableRow
                    key={key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="right">
                      {key}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
