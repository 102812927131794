import React from "react";
import WamlContent, {ModifyWamlOptions, WamlContext} from "./tabs/waml/WAMLContent";
import OverviewContent from "./tabs/overview/OverviewContent";
import OwnershipContent from "./tabs/ownership/OwnershipContent";
import RiskScoreContent from "./tabs/risk-score/RiskScoreContent";
import YAML from 'yaml';
import {decode} from "../../../../utils/encoding";
import {EntityLayout} from "../../../entity/EntityLayout/EntityLayout";
import {EntityHeaderBreadcrumbs} from "../../../entity/EntityHeaderBreadcrumbs/EntityHeaderBreadcrumbs";
import {Link, TableColumn, WarningPanel} from "@backstage/core-components";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import {isMobile} from 'mobile-device-detect';
import {createMetadataDescriptionColumn, createNameColumn, createOwnerColumn} from "../../../entity/utils/tableColumns";
import {isTechDocsAvailable,} from "@backstage/plugin-techdocs";
import DocsContent from "./tabs/docs/DocsContent";
import {RepositoryEntityV1alpha1} from "../../../../../../backend/src/custom-processors/types";
import {useLocalStorage} from "react-use";
import {ChangesNotificationBadge} from "../../../core/ChangesNotificationBadge/ChangesNotificationBadge";
import {useApi} from "@backstage/core-plugin-api";
import {BartApiError, bartApiRef} from "../../../../api/api";
import {WAMLDefinitionPageTitle} from "./tabs/waml/definition/WAMLDefinition";

export type RepositoryPageProps = {
  repositoryEntity: RepositoryEntityV1alpha1;
}

const reposWithChangedWamlLocalStorageKey = "wappsWithChanges";

type ReposWithChangedWaml = {
  repos?: RepoWithChangedWaml[]
}

type RepoWithChangedWaml = {
  slug: string;
  tabsWithChanges: string[];
  waml?: string;
  lastModified: number;
  sessionID?: string;
}

/*
  Note: This component defines the global state of the local WAML and the changes that have been made to it.
  - 'waml' should be used to refer to the string representation of the .weave.yaml
  - 'wapp' should be used after YAML.parse has been called on the 'waml' and is then a YAML object
 */
const RepositoryPage = (props: RepositoryPageProps) => {
  const apiClient = useApi(bartApiRef);

  // get the session id from the url
  // this session id comes from running 'bart ui' so that the user can sync any changes made to the Firestore document
  // that 'bart ui' is watching and then save those changes to their local .weave.yaml file
  const urlParams = new URLSearchParams(window.location.search);
  const sessionID = urlParams.get('session');
  const [getWamlSyncDocError, setGetWamlSyncDocError] = React.useState<string>();

  const [hasStagedChanges, setHasStagedChanges] = React.useState(false);

  // reposWithChangedWaml is a list of repositories that have local changes to their waml
  const [reposWithChangedWaml, setReposWithChangedWaml] = useLocalStorage<ReposWithChangedWaml>(reposWithChangedWamlLocalStorageKey, {});

  // localWaml is meant for temporary changes to the waml that are not yet saved or a part of the source of truth .weave.yaml from the entity
  // it's state lives here so that changes can be shown on the WAML tab even if the user navigates away to a different tab
  const [localWaml, setLocalWaml] = React.useState<string>("");

  const originalWaml = decode(props.repositoryEntity.spec?.waml || "");

  // remove the localWaml from local storage after one day of no changes
  React.useEffect(() => {
    // try to set the local waml from a session id first and local storage second
    // if neither are there, then the original waml is used
    if (sessionID) {
      apiClient.GetWamlSyncDoc(sessionID)
        .then(wamlSyncDoc => {
          if (!wamlSyncDoc.waml) {
            setGetWamlSyncDocError("No WAML found in the sync doc. Contact @devx and let them know there is no 'waml' field in the Firestore document created by running 'bart ui'");
            return;
          }

          // call modifyWaml to set the staged waml in local storage to the state of the waml in the sync doc for the current sessionID
          // this also sets the tabs with changes to include the WAMLDefinitionPageTitle so that if there are changes between the
          // original waml and the waml in the sync doc, it will show the changes notification badge on the definition tab
          modifyWaml(decode(wamlSyncDoc.waml), {tab: WAMLDefinitionPageTitle, sessionID});
        })
        .catch((e: BartApiError) => {
          setGetWamlSyncDocError(e.cause);
        });
    } else {
      let repo = getRepoFromLocalStorage();
      if (!repo) {
        return;
      }

      let oneDay = 24 * 60 * 60 * 1000;
      let now = new Date().getTime();
      if (repo.lastModified < now - oneDay) {
        resetWaml();
      }
    }
  }, []);

  // anytime the localWaml in local storage changes, update the localWaml state
  React.useEffect(() => {
    let repo = getRepoFromLocalStorage();
    if (repo?.waml) {
      setLocalWaml(repo.waml);
    } else {
      setLocalWaml(originalWaml);
    }
  }, [reposWithChangedWaml]);

  // anytime the localWaml changes, determine if there are local changes
  React.useEffect(() => {
    setHasStagedChanges(localWaml !== originalWaml);
  }, [localWaml])

  const modifyWaml = (newWaml: string, options?: ModifyWamlOptions) => {
    // initialize the newReposWithChangedWaml object with the existing reposWithChangedWaml object or the default empty object
    let newReposWithChangedWaml = {
      repos: reposWithChangedWaml?.repos || []
    }

    let r = getRepoFromLocalStorage();
    if (r === undefined) {
      r = {
        slug: props.repositoryEntity.metadata.name,
        tabsWithChanges: [],
        lastModified: new Date().getTime()
      }
    }

    if (options?.sessionID) {
      r.sessionID = options?.sessionID;
    }
    r.lastModified = new Date().getTime();
    if (options?.tab && !r.tabsWithChanges.includes(options?.tab) && newWaml !== originalWaml) {
      r.tabsWithChanges.push(options?.tab);
    }

    // this can happen when the save button is pressed when there are no changes
    if (newWaml !== r.waml) {
      switch (typeof newWaml) {
        case "string":
          r.waml = newWaml
          break;
        case "object":
          r.waml = YAML.stringify(newWaml);
          break;
      }
    }

    if (newWaml === originalWaml) {
      r.tabsWithChanges = [];
    }

    // get all OTHER repos that are not the current repo and add the current one to the list so it can be updated
    let newRepos = newReposWithChangedWaml.repos.filter(repo => repo.slug !== props.repositoryEntity.metadata.name);
    newRepos.push(r);
    newReposWithChangedWaml.repos = newRepos;

    setReposWithChangedWaml(newReposWithChangedWaml);
  }

  const resetWaml = () => {
    let newRepos = reposWithChangedWaml?.repos?.filter(repo => repo.slug !== props.repositoryEntity.metadata.name) || [];
    let r = getRepoFromLocalStorage();
    if (r === undefined) {
      r = {
        slug: props.repositoryEntity.metadata.name,
        tabsWithChanges: [],
        lastModified: new Date().getTime()
      }
    }

    console.log(r)

    r.tabsWithChanges = r.tabsWithChanges.filter(tab => tab !== WAMLDefinitionPageTitle);
    r.waml = originalWaml;
    newRepos.push(r);

    setReposWithChangedWaml({repos: newRepos});
  }

  const getRepoFromLocalStorage = () => {
    return reposWithChangedWaml?.repos?.find(repo => repo.slug === props.repositoryEntity.metadata.name) || undefined
  }

  return (
    <>
      {getWamlSyncDocError && (
        <WarningPanel
          severity="error"
          title={"WAML Sync Doc Error"}
          defaultExpanded={true}
        >
          {getWamlSyncDocError}
        </WarningPanel>
      )}
      <EntityLayout headerRoutingBreadcrumbs={<EntityHeaderBreadcrumbs/>}>
        <EntityLayout.Route path="/" title="Overview">
          {<OverviewContent/>}
        </EntityLayout.Route>
        <EntityLayout.Route path="/docs" title="Docs" if={isTechDocsAvailable}>
          <DocsContent/>
        </EntityLayout.Route>
        {props.repositoryEntity?.spec?.waml && !isMobile && (
          <EntityLayout.Route path="/waml" title="WAML" tabProps={hasStagedChanges ? {
            label: (<ChangesNotificationBadge title={"WAML"}/>)
          } : {}}>
            <WamlContext.Provider value={{
              repositoryEntity: props.repositoryEntity,
              localWaml,
              hasStagedChanges,
              originalWaml,
              tabsWithChanges: getRepoFromLocalStorage()?.tabsWithChanges,
              localSessionID: getRepoFromLocalStorage()?.sessionID || undefined,
              modifyWaml,
              resetWaml
            }}>
              <WamlContent/>
            </WamlContext.Provider>
          </EntityLayout.Route>
        )}
        {props.repositoryEntity?.spec?.waml
          // check for deploys in the WAML
          && Object.keys(YAML.parse(originalWaml).deploy || {}).length != 0
          && (
            <EntityLayout.Route path="/ownership" title="Ownership">
              {<OwnershipContent/>}
            </EntityLayout.Route>
          )}
        {!!props.repositoryEntity?.spec?.qualityMetricsReport && (
          <EntityLayout.Route path="/risk-score" title="Risk Score">
            <RiskScoreContent />
          </EntityLayout.Route>
        )}
      </EntityLayout>
    </>
  )
};

export const createRepositoryTableLinksColumn = (width?: string): TableColumn<CatalogTableRow> => {
  return {
    title: 'Links',
    render: ({entity}) => {
      return (
        <ul>
          <li>
            <Link
              to={`https://github.com/weave-lab/${entity.metadata.name}`}>Source</Link>
          </li>
        </ul>
      )
    },
    width: width,
  };
}

export const getRepositoryTableColumns = (): TableColumn<CatalogTableRow>[] => {
  let columns: TableColumn<CatalogTableRow>[] = [
    createNameColumn({width: "20%"}),
    createOwnerColumn("20%"),
  ];

  if (!isMobile) {
    columns.push(createMetadataDescriptionColumn("50%", 'N/A (No "description" in WAML)'))
    columns.push(createRepositoryTableLinksColumn("15%"));
  }

  return columns;
}

export const RepositoryTableSubtitle = () => {
  return (
    <>The list of repositories comes from <Link to="https://github.com/weave-lab">GitHub</Link> for every
      repository that contains a valid WAML (.weave.yaml) file.</>
  )
}

export default RepositoryPage;
