import React, { useState } from "react";
import {Grid} from "@material-ui/core";
import {configApiRef, useApi, identityApiRef} from "@backstage/core-plugin-api";

import {useEntity} from "@backstage/plugin-catalog-react";
import {SchemaEntityV1alpha1} from "../../../../../../../backend/src/custom-processors/types";

const GoDocContent = () => {
  const {entity} = useEntity<SchemaEntityV1alpha1>();
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef)
  const backendUrl = config.getString('backend.baseUrl');
  var links = `
      <script src="http://code.jquery.com/jquery-1.8.0.js" defer crossorigin="anonymous"></script>
      <link type="text/css" rel="stylesheet" href="${backendUrl}/api/proxy/lib/godoc/style.css">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
      <style>.container{padding: 0px; max-width: unset} div#topbar{display:none;}</style>
      `
  const [goDocContent, setGoDocContent] = useState('')

  // TODO: make '/schemas/' configurable to '/apis/' once support for schema APIs is complete
  // ref: https://linear.app/getweave/issue/DEVX-6878/support-schema-apis-in-switchboard-and-switchboard-cataloger
  let uri = `${backendUrl}/api/proxy/godocs/schemas/${entity.spec.schema.path}?showHeader=false`;
  (async()=>{
    const { token } = await identityApi.getCredentials(); 
    fetch(uri, 
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        return response.text()
      })
      .then(html => {
        setGoDocContent(links+html)
      }).catch(e=>{
        console.log('error', e)
        setGoDocContent('<div><b>Error occurred while fetching go docs for the API, please contact DevX for any help.</b></div>')
      }); 
  })()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {goDocContent &&<div dangerouslySetInnerHTML={{__html: goDocContent}} />}
      </Grid>
    </Grid>
  );
}

export default GoDocContent;
