import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BorderLinearProgress } from './BorderLinearProgress';
import { getNumber, getPercentage } from './helpers';
import ThresholdInfoButton from './ThresholdInfoButton';

interface Props {
  title?: string;
  description?: string;
  numberType?: string;
  recordedValue?: number | string;
  points?: number | string;
  maxPoints?: number | string;
  thresholdType?: string;
  threshold?: Record<string, string | number>;
}

export const MetricInfo = ({
  title = '',
  description = '',
  numberType = '',
  recordedValue = 0,
  points = 0,
  maxPoints = 0,
  thresholdType,
  threshold,
}: Props) => {
  const recordedValueNumber = getNumber(recordedValue);
  const pointsNumber = getNumber(points);
  const maxPointsNumber = getNumber(maxPoints);
  const percentage = getPercentage(pointsNumber, maxPointsNumber);

  const isBoolean = numberType === 'boolean';
  const isPercentage = numberType === 'percentage';

  return (
    <Stack
      direction="row"
      spacing={{ xs: 1, sm: 2, md: 4 }}
      m={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack flex="1 1 40%">
        <Stack direction="row">
          <Typography variant="body1">{title}</Typography>
          <ThresholdInfoButton
            threshold={threshold}
            thresholdType={thresholdType}
          />
        </Stack>
        <Typography color="gray" variant="body2">
          {description}
        </Typography>
      </Stack>
      <Typography
        variant="body1"
        flex="1 1 10%"
        style={{ paddingLeft: isBoolean ? 0 : 5 }}
      >
        {isBoolean && (
          <Checkbox
            checked={pointsNumber !== maxPointsNumber}
            readOnly
            disableRipple
            color="default"
            style={{ padding: 0 }}
            sx={{ ':hover': { cursor: 'unset' } }}
          />
        )}
        {isPercentage && `${recordedValueNumber}%`}
        {!isBoolean && !isPercentage && recordedValueNumber}
      </Typography>

      <BorderLinearProgress
        variant="determinate"
        value={percentage}
        sx={{ minWidth: 120, flex: '1 1 25%' }}
      />
      <Typography variant="body1" flex="1 1 25%">
        {`${pointsNumber} risk points (max ${maxPointsNumber})`}
      </Typography>
    </Stack>
  );
};
